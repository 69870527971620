#root {
    position: relative;
    z-index: 0;
    background: #000 url("../assets/images/stars-compressed.png") repeat top center;

    &::before,
    &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    &::before {
        background: transparent url("../assets/images/twinkling.png") repeat top center;
        animation: move-twink-back 200s linear infinite;
    }
    &::after {
        background: transparent url("../assets/images/cloud-compressed.png") repeat top center;
        animation: move-clouds-back 200s linear infinite;
    }
}

// .starryNight {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     .stars,
//     .twinkling,
//     .clouds {
//         position: absolute;
//         display: block;
//         top: 0;
//         bottom: 0;
//         left: 0;
//         right: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 0;
//     }

//     .stars {
//         // z-index: 0;
//         // background: #000 url("https://image.ibb.co/mjnygo/stars.png") repeat top center;
//     }

//     .twinkling {
//         // z-index: 0;
//         // background: transparent url("https://image.ibb.co/ir1DE8/twinkling.png") repeat top center;
//         // animation: move-twink-back 200s linear infinite;
//     }

//     .clouds {
//         // z-index: 0;
//         // background: transparent url("https://image.ibb.co/bT4N7T/clouds.png") repeat top center;
//         // animation: move-clouds-back 200s linear infinite;
//     }
// }

@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 10000px 0;
    }
}
