@import "./variables";

.social-share-page {
    margin-top: 100px;
    .wrapper {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            flex-wrap: wrap;

            li {
                padding: 20px;
                @media (max-width: 414px) {
                    padding: 10px;
                }
                img {
                    width: 50px;
                    height: auto;
                    color: $Gray;
                    transition: all 0.5s ease;

                    @media (max-width: 615px) {
                        width: 50px;
                    }
                }
            }
        }
    }
}
