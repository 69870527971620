// Neon Borders:
$White: #ffffff;
$NeonBlue: #00ffff;
$Red: #ff2e00;
$Blue: #0066ff;
$Orange: #ff7b19;

// Background:
$Black: #000000;
// Text: ( All Text Font - Eurostyle bold)
$Gray: #dddddd;
