@import "./variables";

html {
    scroll-behavior: smooth;
}
#root {
    z-index: 10;
}
.app {
    z-index: 20;
}

@mixin generateNeonBorder($colorName) {
    box-shadow: 0 0 0vw 0vw $colorName, 0 0 20px 0vw $colorName, 0 0 20px 0vw $colorName, inset 0 0 5px 2px $colorName,
        inset 0 0 0.4vw 0vw $colorName, inset 0 0 5px 2px $colorName;
}

/* global blue neon box-shado effect */
.neon-blue-border {
    @include generateNeonBorder($Blue);
}
.neon-neon-blue-border {
    @include generateNeonBorder($NeonBlue);
}
.neon-red-border {
    @include generateNeonBorder($Red);
}

// light neon
.neon-neon-blue-border-light {
    box-shadow: 0 0 0vw 0vw $NeonBlue, 0 0 7px 0vw $NeonBlue, 0 0 12px 0vw $NeonBlue, inset 0 0 4px 0px $NeonBlue,
        inset 0 0 0vw 0vw $NeonBlue, inset 0 0 0px 0px $NeonBlue;
}
.neon-red-border-light {
    box-shadow: 0 0 0vw 0vw $Red, 0 0 7px 0vw $Red, 0 0 12px 0vw $Red, inset 0 0 4px 0px $Red, inset 0 0 0vw 0vw $Red,
        inset 0 0 0px 0px $Red;
}
/* scrollbar */
/* width */
::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
    background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $Blue;
    // @extend .neon-blue-border;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $Red;
}

// global container
.container-1320 {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
}

// same margin between two sections
.margin-top-120 {
    margin-top: 120px;
}
.margin-bottom-120 {
    margin-bottom: 120px;
}

// background of transprent neon color and grid
.bg-grid-and-neon {
    background-image: url("../assets/images/josh-screen-grid-bg.svg"),
        url("../assets/images/josh-color-semitransparent-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

// glowing text
.text-glow {
    text-shadow: 0 0 0 transparent, 0 0 10px $Red, 0 0 20px rgba(38, 149, 255, 0.5), 0 0 40px $Red, 0 0 100px $Red,
        0 0 200px $Red, 0 0 300px $Red, 0 0 500px $Red;
    -webkit-animation: buzz 0.01s infinite alternate;
    animation: buzz 2s infinite alternate, blink 4s infinite alternate;
}
// text glow less shining
.text-glow-moderate{
    text-shadow: 0 0 0 transparent,
    0 0 10px $Red,
    0 0 20px rgb(38 149 255 / 50%),
    0 0 40px $Red,
    0 0 20px #ff2e0091,
    0 0 20px #ff2e0029,
    0 0 20px #ff2e000d,
    0 0 20px #ff2e001f;
    -webkit-animation: buzz 0.01s infinite alternate;
    animation: buzz 2s infinite alternate,
    blink 4s infinite alternate;
}

// generate dynamic animation durations
@mixin text-glow-timing {
    @for $i from 1 through 20 {
        .text-glow-#{$i}s {
            animation: buzz 2s infinite alternate, blink #{$i}s infinite alternate;
        }
    }
}
@include text-glow-timing;

.text-glow-normal-neon-blue {
    text-shadow: 0px 0px 14px $NeonBlue;
}
.hover-glow-shadow-red {
    box-shadow: 0px 0px 20px #ff2e00;
    text-shadow: 0px 0px 20px #ff2e00, 0px 0px 5px #ff7b19;
}
.josh-font {
    font-family: JoshBrett, Arial, Helvetica, sans-serif !important;
}
.page-heading {
    font-size: 50px;
}

// apply and submit buttons border glow
.neon-blue-border-normal-glow {
    box-shadow: 0px 0px 15px #00ffff;
}

// headings bottom border neon blue
.neon-border-bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

// animation
@keyframes buzz {
    70% {
        opacity: 0.8;
    }
}
@-webkit-keyframes buzz {
    70% {
        opacity: 0.8;
    }
}
@keyframes blink {
    40% {
        opacity: 1;
    }
    42% {
        opacity: 0.8;
    }
    43% {
        opacity: 1;
    }
    45% {
        opacity: 0.2;
    }
    46% {
        opacity: 1;
    }
}
@-webkit-keyframes blink {
    40% {
        opacity: 1;
    }
    42% {
        opacity: 0.8;
    }
    43% {
        opacity: 1;
    }
    45% {
        opacity: 0.2;
    }
    46% {
        opacity: 1;
    }
}
