@tailwind base;
@tailwind components;
@tailwind utilities;

/*  setting global font */
@font-face {
    font-family: "JoshBrett";
    src: url("./assets/fonts/neontubes/neontubes-webfont.woff") format("woff"),
        url("./assets/fonts/neontubes/neontubes-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Euphemia";
    src: url("./assets/fonts/euphemia/Euphemia-UCAS-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "EuphemiaRegular";
    src: url("./assets/fonts/euphemia/Euphemia-UCAS-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Eurostile";
    src: url("./assets/fonts/eurostile/EurostileBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

html * {
    font-family: Euphemia, Arial, Helvetica, sans-serif;
    font-weight: normal;
}
html,
body {
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}

/* app background */
body {
    background-color: #000;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB3aWR0aD0nNjAwJyBoZWlnaHQ9JzYwMCcgdmlld0JveD0nMCAwIDE1MCAxNTAnPgo8ZmlsdGVyIGlkPSdpJyB4PScwJyB5PScwJz4KCTxmZUNvbG9yTWF0cml4IHR5cGU9J21hdHJpeCcgdmFsdWVzPScxIDAgMCAwIDAgIDAgMSAwIDAgMCAgMCAwIDEgMCAwICAwIDAgMCAwIDAnIC8+CjwvZmlsdGVyPgo8ZmlsdGVyIGlkPSduJyB4PScwJyB5PScwJz4KCTxmZVR1cmJ1bGVuY2UgdHlwZT0ndHVyYnVsZW5jZScgYmFzZUZyZXF1ZW5jeT0nLjcnIHJlc3VsdD0nZnV6eicgbnVtT2N0YXZlcz0nMicgc3RpdGNoVGlsZXM9J3N0aXRjaCcvPgoJPGZlQ29tcG9zaXRlIGluPSdTb3VyY2VHcmFwaGljJyBpbjI9J2Z1enonIG9wZXJhdG9yPSdhcml0aG1ldGljJyBrMT0nMCcgazI9JzEnIGszPSctNzMnIGs0PScuMDEnIC8+CjwvZmlsdGVyPgo8cmVjdCB3aWR0aD0nMTAyJScgaGVpZ2h0PScxMDIlJyBmaWxsPScjMDMwMzFhJy8+CjxyZWN0IHg9Jy0xJScgeT0nLTElJyB3aWR0aD0nMTAyJScgaGVpZ2h0PScxMDIlJyBmaWxsPScjZmZmZmZmJyBmaWx0ZXI9J3VybCgjbiknIG9wYWNpdHk9JzEnLz4KPHJlY3QgeD0nLTElJyB5PSctMSUnIHdpZHRoPScxMDIlJyBoZWlnaHQ9JzEwMiUnIGZpbGw9JyMwMzAzMWEnIGZpbHRlcj0ndXJsKCNpKScgb3BhY2l0eT0nMScvPgo8L3N2Zz4=");
}

/* font classes */
.EuphemiaRegular {
    font-family: EuphemiaRegular, Arial, Helvetica, sans-serif;
    font-weight: normal;
}
.Eurostile {
    font-family: Eurostile, Arial, Helvetica, sans-serif;
    font-weight: normal;
}
