@import "_variables.scss";

@mixin grid {
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    align-content: center;
    -webkit-align-content: center;
}
@mixin cell {
    width: 10px;
    height: 10px;
    background: $bg-color;
    border-radius: 50%;
    margin: 2px;
}
.neon-loader-container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    width: 60%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    align-items: center;
    -webkit-align-items: center;
    align-content: center;
    -webkit-align-content: center;
    z-index: -1;
}

.grid-big {
    @include grid;
    width: 70px;
    height: 70px;
}

$glow-color: $NeonBlue;
$bg-color: darken($glow-color, 40);

$cell1-order: (7, 8, 9, 12, 14, 17, 18, 19);

//outer layer
.cell-1 {
    @include cell;
    animation: quick-glow 1.25s 0.3s infinite linear;
    -webkit-animation: quick-glow 1.25s 0.3s infinite linear;
}

//middle layer
@for $i from 0 to length($cell1-order) {
    .cell-1:nth-child(#{nth($cell1-order, $i+1)}) {
        animation: quick-glow 1.25s 0.15s infinite linear;
        -webkit-animation: quick-glow 1.25s 0.15s infinite linear;
    }
}

//center cell
.cell-1:nth-child(13) {
    animation: quick-glow 1.25s infinite linear;
    -webkit-animation: quick-glow 1.25s infinite linear;
}

/****** KEYFRAME ANIMATIONS ******/

@keyframes quick-glow {
    15% {
        z-index: 99;
        background: $glow-color;
        box-shadow: 0 0 10px 2px $glow-color;
    }
}

@keyframes glow {
    50% {
        z-index: 99;
        background: $glow-color;
        box-shadow: 0 0 10px 2px $glow-color;
    }
}

@-webkit-keyframes quick-glow {
    15% {
        z-index: 99;
        background: $glow-color;
        box-shadow: 0 0 10px 2px $glow-color;
    }
}

@-webkit-keyframes glow {
    50% {
        z-index: 99;
        background: $glow-color;
        box-shadow: 0 0 10px 2px $glow-color;
    }
}
