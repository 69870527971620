@import "_variables.scss";

// @apply tailwind css classes

header {
    &.header {
        transition: transform 0.3s;
        &.bottom {
            transform: scale(0);
        }
        &.active {
            transform: scale(1);
        }
        .logo-bg {
            svg {
                height: 100%;
                width: 100%;
            }
        }
        @media screen and (max-width: 768px) {
            .logo-img {
                height: 90px;
            }
            .logo-bg {
                height: 75px;
            }
        }

        .hamburger {
            .middle,
            .top,
            .bottom {
                transition: all 0.3s;
            }
            &.is-active {
                .middle {
                    display: none;
                    transition-delay: 0.22s;
                    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                    transform: translate3d(0, -10px, 0) rotate(-45deg);
                }
                .top {
                    transition: all 0.3s;
                    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                    transform: rotate(225deg);
                }
                .bottom {
                    transition: all 0.3s;
                    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                    transform: rotate(-42deg);
                    margin-top: -3px;
                }
            }

            @media screen and (max-width: 768px) {
                .top,
                .bottom {
                    width: 40px;
                    height: 4px;
                }
                .middle {
                    width: 60px;
                }
                img {
                    margin-top: 0.7rem;
                }
            }
        }
    }
}

.sub-menu .menu-item {
    padding: 1rem;
    background: $Black;
    color: $Gray;
    opacity: 0;
    transform-origin: bottom;
    animation: enter 0.2s ease forwards;
}
.sub-menu .menu-item:nth-child(1) {
    animation-duration: 0.2s;
    animation-delay: 0s;
}
.sub-menu .menu-item:nth-child(2) {
    animation-duration: 0.3s;
    animation-delay: 0.1s;
}
.sub-menu .menu-item:nth-child(3) {
    animation-duration: 0.4s;
    animation-delay: 0.2s;
}
.sub-menu .menu-item:hover {
    background: rgb(7, 7, 7);
    border: 1px solid rgba(255, 255, 255, 0.096);
    border-bottom: 0px;
    border-top: 0px;

    color: $NeonBlue;
    text-shadow: 0 0 0 transparent, 0 0 10px $NeonBlue, 0 0 20px rgba(38, 149, 255, 0.5), 0 0 40px $NeonBlue,
        0 0 100px $NeonBlue, 0 0 200px $NeonBlue, 0 0 300px $NeonBlue, 0 0 500px $NeonBlue;
}
.sub-menu .menu-item a {
    padding: 0 0.75rem;
}
.sub-menu {
    position: absolute;
    width: 165px;
    top: 40px;
    right: 12px;
    display: none;
    z-index: 9999999;
}
.sub-menu:before {
    content: "";
    top: -20px;
    right: 0px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid rgb(34, 34, 34);
    transform: rotate(180deg);
    clear: both;
}

@keyframes enter {
    from {
        opacity: 0;
        transform: scaleY(0.98) translateY(10px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
